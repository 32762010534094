// Removalbe text item
// Shows text on the left
// Shows Clear icon on the right
// Event should fire on Clear button click
// Should take text as a prop

import React from 'react';
import { Typography, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';


export default function RemovableText ({ deleteDiner, value })  {

  const handleOnClick = (event, diner) => {
    deleteDiner(diner);
  }

  return (
    <Paper
      square={true}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Typography>{value}</Typography>
      <DeleteIcon onClick={(event) => {handleOnClick(event, value)}} value={value}
        fontSize="medium"
      />
    </Paper>
  )

}