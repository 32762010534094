import React, { useEffect, useState } from "react"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DinerController from './DinerController.jsx';
import ItemController from './ItemController.jsx';
import RatesController from './RatesController.jsx';
import SplitController from './SplitController.jsx';

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  export default function AppContainer() {
    const [paneId, setPaneId] = React.useState(0);
  
    const [diners, setDiners] = useState([ ]);
    const [items, setItems]   = useState([ ]);

    const [globalCounter, setGlobalCounter] = useState(1);

    const [taxRate, setTaxRate] = useState(0);
    const [tipRate, setTipRate] = useState(20);

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
      if (firstLoad) {
        const storedPane = localStorage.getItem('pane') || "0";
        setPaneId(JSON.parse(storedPane));

        const storedDiners = localStorage.getItem('diners') || '[]';
        setDiners(JSON.parse(storedDiners));  

        const storedItems = localStorage.getItem('items') || '[]';
        setItems(JSON.parse(storedItems));

        const storedCounter = localStorage.getItem('globalCounter') || "1";
        setGlobalCounter(JSON.parse(storedCounter));

        const storedTax = localStorage.getItem('taxRate') || "10";
        setTaxRate(JSON.parse(storedTax));

        const storedTip = localStorage.getItem('tipRate') || "20";
        setTipRate(JSON.parse(storedTip));

        setFirstLoad(false);
      }
    }, [firstLoad, setFirstLoad])

    const handleChange = (event, newValue) => {
      setPaneId(newValue);
      localStorage.setItem('pane', JSON.stringify(newValue));
    };
  
    // Add a new diner to the list, preventing duplicates
    const addDiner = (diner) => {
      if(diner === "") {
        return;
      }
      const updatedDiners = [
        ...diners, diner
      ];
      const dedupDiners = updatedDiners.filter(
        (value, index) => updatedDiners.indexOf(value) === index
        );
      setDiners(dedupDiners);
      localStorage.setItem('diners', JSON.stringify(dedupDiners));
    }

    // Delete a diner from the list, if it exists
    const deleteDiner = (diner) => {
      const dinerIndex = diners.indexOf(diner);
      if(dinerIndex !== -1) {
        const updatedDiners = [...diners];
        updatedDiners.splice(dinerIndex, 1);
        setDiners(updatedDiners);
        localStorage.setItem('diners', JSON.stringify(updatedDiners));
        // Also delete that diner from any item it is part of
        const updatedItems = items.map(updateItem => {
          if(updateItem.diners.indexOf(diner) !== -1) {
            updateItem.diners = updateItem.diners.filter(origDiner => origDiner !== diner)
          }
          return updateItem;
        })
        setItems(updatedItems);
        localStorage.setItem('items', JSON.stringify(updatedItems));  
      }
    }

    const getGlobalCounter = () => {
      const newCount = globalCounter;
      setGlobalCounter(newCount+1);
      localStorage.setItem('globalCounter', JSON.stringify(newCount+1));
      return newCount+1;
    }

    const addItem = (name, cost, newDiners) => {
      const newItem = {
        name: name,
        cost: cost,
        diners: [...newDiners],
        id: getGlobalCounter()
      };
      const updatedItems = [...items, newItem];
      setItems(updatedItems);
      localStorage.setItem('items', JSON.stringify(updatedItems));
    }

    const deleteItem = (id) => {
      const filteredItems = [...items];
      const delItems = filteredItems.filter( (item) => id !== item.id);
      setItems(delItems);
      localStorage.setItem('items', JSON.stringify(delItems));
    }

    const updateItem = (item) => {
      const updatedItems = items.map(updateItem => {
        if(item.id === updateItem.id) {
          updateItem.name = item.name;
          updateItem.cost = item.cost;
          updateItem.diners = item.diners;
        }
        return updateItem;
      })
      setItems(updatedItems);
      localStorage.setItem('items', JSON.stringify(updatedItems));
    }
  
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={paneId} onChange={handleChange} aria-label="app main tabs">
            <Tab label="Diners" {...a11yProps(0)} />
            <Tab label="Items"  {...a11yProps(1)} />
            <Tab label="Rates"  {...a11yProps(2)} />
            <Tab label="Split"  {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={paneId} index={0}>
          <DinerController diners={diners} addDiner={addDiner} deleteDiner={deleteDiner}/>
        </CustomTabPanel>
        <CustomTabPanel value={paneId} index={1}>
          <ItemController items={items} addItem={addItem} updateItem={updateItem} deleteItem={deleteItem} diners={diners}/>
        </CustomTabPanel>
        <CustomTabPanel value={paneId} index={2}>
          <RatesController items={items} taxRate={taxRate} setTaxRate={setTaxRate} tipRate={tipRate} setTipRate={setTipRate}/>
        </CustomTabPanel>
        <CustomTabPanel value={paneId} index={3}>
          <SplitController diners={diners} items={items} taxRate={taxRate} tipRate={tipRate}/>
        </CustomTabPanel>
      </Box>
    );
  }
  