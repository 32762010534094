import * as React from 'react';
import { Table, TableBody, TableHead, TableCell, TableContainer, TableRow,
     Box, Typography, Paper, Alert, Button } from '@mui/material';

import ItemEditor from './ItemEditor.jsx';

export default function ItemController ( {items, addItem, updateItem, deleteItem, diners } ) {

    const itemsMissingDiners = () => {
        if(items.length === 0 ) {
            return 0;
        }
        return items.every((item) => {
            return item.diners.length === 0;
        });
    }

    const handleOnClick = (event) => {
            event.preventDefault();
            let confirmed = window.confirm('Really clear all Items?')
            if (confirmed) {
                items.forEach((item) => {
                    deleteItem(item.id);
                });
            }
        }
    
    return (
        <Box style={{textAlign: "left"}}>
            <Typography variant="h1">Items</Typography>
            {
                itemsMissingDiners()
                ? <Alert severity="warning">Some Items do not have a Diner.</Alert>
                : null
            }
            <TableContainer component={Paper}>
            <Table style={{ width: "100%" }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width:"25%"}}>Item</TableCell>
                        <TableCell style={{width:"25%"}}>Cost</TableCell>
                        <TableCell style={{width:"25%"}}>Diner(s)</TableCell>
                        <TableCell style={{width:"25%"}}><Button onClick={handleOnClick}>Clear All</Button></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                {
                    items.map ( (item) => {
                        return (
                                <ItemEditor
                                    mode='show'
                                    addItem={addItem}
                                    updateItem={updateItem}
                                    deleteItem={deleteItem}
                                    item={item}
                                    diners={diners}
                                />
                        )
                    })
                }
                <ItemEditor mode='edit' updateItem={updateItem} addItem={addItem} item={{diners:[]}} diners={diners} />
                </TableBody>
            </Table>
            </TableContainer>
        </Box>
    )
}

