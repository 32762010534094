import * as React from 'react';
import { Paper } from '@mui/material';

import AppContainer from './components/AppContainer.jsx';

import './App.css';

function App() {
  return (
    <Paper  className="App">
      <AppContainer />
    </Paper>
  );
}

export default App;
