import * as React from 'react';
import { List, ListItem, Box, Typography, Alert } from '@mui/material';

import AddDiner from './AddDiner.jsx';
import RemovableText from './RemovableText.jsx';

export default function DinerController ( {diners, addDiner, deleteDiner} ) {
    // Delete a diner from the list, if it exists
    return (
        <Box style={{textAlign: "left"}}>
            <Typography variant="h1">Diners</Typography>
            {
                diners.length === 0
                ? <Alert severity="error">No diners are defined</Alert>
                : null
            }
            <Typography>Diner List:</Typography>
            <List>
                {
                    diners.map( (diner) => {
                        return (
                                <ListItem key={diner}>
                                    <RemovableText deleteDiner={deleteDiner} value={diner} />
                                </ListItem>
                        )
                    })
                }
                <ListItem key="0">
                    <AddDiner addDiner={addDiner} />
                </ListItem>
            </List>
        </Box>
    )
}

