import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { NumericFormat } from 'react-number-format';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';


import {  IconButton, TextField, ListItemText,
          OutlinedInput, InputLabel,
          MenuItem, FormControl, Select, Checkbox, TableCell, TableRow
       } from '@mui/material';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ItemEditor ( { mode, addItem, updateItem, deleteItem, diners, item } )  {
  const [itemName, setItemName] = useState(item.name);
  const [itemCost, setItemCost] = useState(item.cost);
  const [costError, setCostError] = useState("");
  const [dinerNames, setDinerNames] = useState(item.diners);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
      // On autofill we get a stringified value.
      const arrayValue = typeof value === 'string' ? value.split(',') : value;
      setDinerNames(arrayValue);
      item.diners = arrayValue;
      updateItem(item);
  };

  const handleNameOnChange = (event) => {
    setItemName(event.target.value);
    item.name = event.target.value;
    updateItem(item);
  }

  const handleCostOnChange = (event) => {
    setItemCost(event.target.value);
    item.cost = event.target.value;
    updateItem(item);
  }

  // Capture keydown so "enter" saves
  const handleNameKeyDown = (event) => {
    if (event.keyCode === 13) {
      if(mode ==='edit') {
        addAnItem();
      }
    }
  }
  
  const handleCostKeyDown = (event) => {
    if (event.keyCode === 13) {
      if(mode === 'edit') {
        addAnItem();
      }
    }
  }

  const handleAddOnClick = () => {
    addAnItem();
  }

  const handleDeleteOnClick = (event, id) => {
    deleteItem(id);
  }

  const addAnItem = () => {
    if(itemCost === ''  || itemCost === undefined) {
      setCostError('Cost cannot be blank');
    }
    else if (dinerNames.length === 0) {
      setCostError('Must select at least one diner.');
    }
    else {
      addItem(itemName, itemCost, dinerNames);
      setItemName('');
      setItemCost('');
      setCostError('');
      setDinerNames([]);
    }
  }

  return (
      <TableRow>
      <TableCell>
          <TextField 
            value={itemName}
            id="item-name"
            label="Item Name"
            variant="standard"
            margin="none"
            size="small"
            onChange={handleNameOnChange}
            onKeyDown={handleNameKeyDown}
          />
      </TableCell>

      <TableCell>
        <TextField 
          value={itemCost}
          id="item-cost"
          label="Item Cost"
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          variant="standard"
          margin="none"
          size="small"
        onChange={handleCostOnChange}
          onKeyDown={handleCostKeyDown}
          error={costError}
          helperText={costError}
        />
      </TableCell>

      <TableCell>
        <FormControl >
          <InputLabel id="items-multiple-checkbox-label"
            margin="none"
            size="small"
          >Diner(s)</InputLabel>
          <Select
            labelId="items-multiple-checkbox-label"
            id="items-multiple-checkbox"
            multiple
            value={dinerNames}
            onChange={handleSelectChange}
            input={<OutlinedInput label="Diner(s)" />}
            renderValue={(selected) => { return selected.join(', ')
            }}
            
            MenuProps={MenuProps}
          >
            {diners.map((name) => ( 
              <MenuItem key={name} value={name}>
                <Checkbox checked={dinerNames.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>

      <TableCell>
        { mode === 'edit' ? (
          <IconButton aria-label='Add item'
            onClick={handleAddOnClick}>
            <AddIcon />
          </IconButton>
        ) : (
          <IconButton aria-label='Delete item' 
            onClick={(event) => {handleDeleteOnClick(event, item.id)}}
          >
            <DeleteIcon />
          </IconButton>

        )}
      </TableCell>
  </TableRow>

  )
}