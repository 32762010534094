import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';


import IconButton from '@mui/material/IconButton';
import { Box, TextField } from '@mui/material';

export default function AddDiner ( { addDiner, deleteDiner } )  {
  const [value, setValue] = useState('');

  // Should rename this, it's not really a submit.
  const handleOnSubmit = (event) => {
    event.preventDefault();
    addDiner(value)    
    setValue('');    
  }

  // The text field always shows the value stored in "value"
  // so for typing to work, we have to update that value when
  // changes are made.
  const handleOnChange = (event) => {
    setValue(event.target.value);
  }

  // Capture keydown so "enter" saves
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      addDiner(value)
      setValue('');
    }
  }
  
  return (
    <Box>
        <TextField 
          value={value}
          id="standard-basic"
          label="Enter New Diner"
          variant="standard"
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
        />
        <IconButton aria-label='Add diner' onClick={handleOnSubmit}>
            <AddIcon />
        </IconButton>
    </Box>
  )
}