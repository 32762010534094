import * as React from 'react';
import { Box, Paper, Typography, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';

export default function SplitController ({diners, items, taxRate, tipRate}) {
    const currency = new Intl.NumberFormat(undefined, {currency: 'USD', style: 'currency'});

    const dinerSubtotal = (diner) => {
        const dinerSub = items.map((item) => {
            if(item.diners.indexOf(diner) === -1) {
                return 0;
            }
            else {
                return item.cost / item.diners.length;
            }
        }).reduce((total, cost) => {
            return total + cost;
        }, 0);
        return dinerSub;
    }

    const getSubtotal = () => {
        return items.reduce( (total, value) => {
            return total + Number(value.cost);
        }, 0.0);
    }

    const getTotalTax = () => {
        return getSubtotal() * (taxRate/100);
    }

    const dinerTax = (diner) => {
        const dinerTax = dinerSubtotal(diner) * (taxRate/100);
        return dinerTax;
    }

    const dinerTip = (diner) => {
        const dinerTip = dinerSubtotal(diner) * (tipRate/100);
        return dinerTip;
    }

    const getTotalTip = () => {
        return getSubtotal() * (tipRate/100);
    }

    const splitUp = (diner) => {
        const dinerIdx = diners.indexOf(diner);
        return (
            <TableContainer>
            <Table style={{ width: "auto", tableLayout: "auto" }} size="small">
            <TableRow>
                <TableCell align="right" variant="head">Subtotal:</TableCell>
                <TableCell data-testid={'diner-' + dinerIdx + '-subtotal'}>{currency.format(dinerSubtotal(diner))}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" variant="head">Tax:</TableCell>
                <TableCell data-testid={'diner-' + dinerIdx + '-tax'}>{currency.format(dinerTax(diner))}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" variant="head">Tip:</TableCell>
                <TableCell data-testid={'diner-' + dinerIdx + '-tip'}>{currency.format(dinerTip(diner))}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" variant="head">Total:</TableCell>
                <TableCell data-testid={'diner-' + dinerIdx + '-total'}>{currency.format(dinerSubtotal(diner) + dinerTax(diner) + dinerTip(diner))}</TableCell>
            </TableRow>
            </Table>
            </TableContainer>
        );
    }

    const totals = () => {
        return (
            <TableContainer>
            <Table style={{ width: "auto", tableLayout: "auto" }} size="small">
            <TableRow>
                <TableCell align="right" variant="head">Subtotal:</TableCell>
                <TableCell data-testid="total-subtotal">{currency.format(getSubtotal())}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" variant="head">Tax:</TableCell>
                <TableCell data-testid="total-tax">{currency.format(getTotalTax())}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" variant="head">Tip:</TableCell>
                <TableCell data-testid="total-tip">{currency.format(getTotalTip())}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="right" variant="head">Total:</TableCell>
                <TableCell data-testid="total-total">{currency.format(getSubtotal() + getTotalTax() + getTotalTip())}</TableCell>
            </TableRow>
            </Table>
            </TableContainer>
        );
    }

    return (
        <Box style={{textAlign: "left"}}>
            <Typography variant="h1">Split</Typography>
            <TableContainer component={Paper}>
                <Table style={{ width: "auto", tableLayout: "auto" }} size="small">
                    <TableBody>
                        {
                            diners.map( (diner) => {
                                return (
                                <TableRow>
                                    <TableCell align="right">{diner}:</TableCell>
                                    <TableCell>{splitUp(diner)}</TableCell>
                                </TableRow>        
                                );
                            })
                        }
                        <TableRow>
                            <TableCell align="right">Total:</TableCell>
                            <TableCell>{totals()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
}

