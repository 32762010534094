import React, { useState } from 'react';
import { Box, Paper, Typography, TableContainer, Table, TableBody, TableRow, TableCell, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { NumericFormat } from 'react-number-format';

const NumericFormatCurrency = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;
  
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        decimalScale={2}
        fixedDecimalScale
        prefix="$"
      />
    );
  });
  
  NumericFormatCurrency.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  const NumericFormatPercent = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;
  
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        valueIsNumericString
        suffix="%"
      />
    );
  });
  
  NumericFormatPercent.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  export default function RatesController ( {items, taxRate, setTaxRate, tipRate, setTipRate } ) {

    const currency = new Intl.NumberFormat(undefined, {currency: 'USD', style: 'currency'});

    const getSubtotal = () => {
        return items.reduce( (total, value) => {
            return total + Number(value.cost);
        }, 0.0);
    }

    const [editTaxRate, setEditTaxRate ]     = useState(taxRate);
    const [editTaxAmount, setEditTaxAmount ] = useState(Number(taxRate/100) * Number(getSubtotal()));

    const [editTipRate, setEditTipRate ] = useState(tipRate);

    const [editState, setEditState ] = useState('none');

    const handleTaxRateOnChange = async (event) => {
        if(editState === 'none') {
            await setEditState('taxrate');
            setEditTaxRate(event.target.value);
            setTaxRate(event.target.value);
            const newTaxAmt = ((Number(event.target.value)/100)
                                * getSubtotal());
            await setEditTaxAmount(newTaxAmt);
            await setEditState('none');
        }
    }

    const handleTaxAmountOnChange = async (event) => {
        if(editState === 'none') {
            await setEditState('taxamt');
            setEditTaxAmount(event.target.value);
            const newTaxRate = ((Number(event.target.value) / getSubtotal()) *100);
            setEditTaxRate(newTaxRate);
            await setTaxRate(newTaxRate);
            await setEditState('none');
        }
    }

    const handleTipRateOnChange = (event) => {
        setEditTipRate(event.target.value);
        setTipRate(event.target.value);
    }

    return (
        <Box style={{textAlign: "left"}}>
            <Typography variant="h1">Rates</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell align="right">Subtotal:</TableCell>
                            <TableCell>{currency.format(getSubtotal())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">Tax Rate:</TableCell>
                            <TableCell>
                                <TextField 
                                    value={editTaxRate}
                                    id="edit-tax-rate"
                                    label="Tax Rate"
                                    InputProps={{
                                        inputComponent: NumericFormatPercent,
                                      }}                                    
                                    variant="standard"
                                    margin="none"
                                    size="small"
                                    onChange={handleTaxRateOnChange}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">Total Tax:</TableCell>
                            <TableCell>
                                <TextField 
                                    value={editTaxAmount}
                                    id="edit-tax-amunt"
                                    label="Tax Amount"
                                    InputProps={{
                                        inputComponent: NumericFormatCurrency,
                                    }}                                    
                                    variant="standard"
                                    margin="none"
                                    size="small"
                                    onChange={handleTaxAmountOnChange}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">Tip Rate:</TableCell>
                            <TableCell>
                                <TextField 
                                    value={editTipRate}
                                    id="edit-tip-rate"
                                    label="Tip Rate"
                                    InputProps={{
                                        inputComponent: NumericFormatPercent,
                                    }}                                    
                                    variant="standard"
                                    margin="none"
                                    size="small"
                                    onChange={handleTipRateOnChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

